import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"
import findDealer from "../findDealer/findDealer";
import { FindDealerContext } from "../findDealer/context/findDealerContext";

import grid from "../../scss/flexboxgrid.module.scss"
import view from "./dealers.module.scss"

export default function DealerArray(props) {
  const [dealers, setDealers] = useState([])
  const { loadingList, localStorageDistanceData, scrollToListRef, setClosestDealerCompPresent } = useContext(FindDealerContext);
  const {leftBtnLink, leftBtnTxt, rightBtnLink, rightBtnTxt} = props.data
  useEffect(() => {
    setDealers(props.dealers || [])
    setClosestDealerCompPresent(true);
  }, [])

  useEffect(() => {
    if (localStorageDistanceData) {
      const sortedDealers = localStorageDistanceData.closest_dealers || []
      const procDealers = sortedDealers.map(sortedDealer => {
        const dealerData = props.dealers.find(dealer => dealer.dealerName === sortedDealer.dealerName)
        return { ...sortedDealer, ...dealerData }
      })
      setDealers(procDealers)
    }
  }, [localStorageDistanceData])

  const renderLink = (BtnLink, BtnTxt, dealer) => (
    <Link to={dealer[BtnLink]} className={view["card-button"]}>{BtnTxt}</Link>
  )

  const renderButton = (dealer) => (
    <div className={view["buttons"]}>
        {(leftBtnTxt != "" ? renderLink(leftBtnLink, leftBtnTxt, dealer) : <></>)}
        {(rightBtnTxt != "" ? renderLink(rightBtnLink,rightBtnTxt, dealer) : <></>)}
    </div>
)

  const renderDealer = (dealer) => (
    <div className={view["dealer-card"]}>
      <img alt={dealer.dealerName + ' Card Image'} className={view["card-img"]} src={dealer.dealerServiceImage} />
      <p className={view["dealer-title"]}>{dealer.dealerName}</p>
      {dealer.distances && 
        <p className={view["dealer-dist"]}>
          {`${dealer.distances.distance.text} Away`}
        </p> 
      }
      <a className={view["card-links"]} href={"https://maps.google.com/?q="+ dealer.dealerAddress}>{dealer.dealerAddress}</a>
      <a className={view["card-links"]} href={"tel:" + dealer.mainPhoneNumber}>{dealer.mainPhoneNumber}</a>
        {renderButton(dealer)}
    </div>
  )

  return (
    <section className={view["array-wrap"]} ref={scrollToListRef}>
      <div className={view["array-cont"]}>
        {dealers.length > 0 && dealers.map(dealer => (
          <div className={`${grid["col-xs-12"]} ${grid['col-sm-6']} 
            ${grid["col-md-4"]} ${grid["col-lg-3"]}`}>
            {renderDealer(dealer)}
          </div>
        ))}
      </div>
      {loadingList && <div className={view["loading-string"]}><p>Loading...</p></div>}
    </section>
  )
}

DealerArray.defaultProps = {
  dealers: []
}
